import React, { useState } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import phoneIcon from 'images/icons/phone.svg'
import mailIcon from 'images/icons/mail.svg'
import menuIcon from 'images/menu.svg'
import menuIconActive from 'images/menu-active.svg'
import { EMAIL, PHONE_NUMBER } from 'constants/contact'

const MenuButton = styled.div`
  cursor: pointer;
`

interface MenuWrapperProps {
  $isOpen: boolean
}

const MenuWrapper = styled.div<MenuWrapperProps>`
  position: fixed;
  z-index: 1000;
  top: 50px;
  left: 0;
  right: 0;
  bottom: -100px;

  background-color: ${({ theme }) => theme.colors.dark800};
  color: ${({ theme }) => theme.colors.white00};
  display: ${({ $isOpen }) => ($isOpen ? 'block' : 'none')};

  a {
    transition: color 0.15s;
  }

  a:hover {
    color: ${({ theme }) => theme.colors.lightGrey100};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: none;
  }

  .menu-item {
    text-align: center;
    margin: 20px auto;
    padding: 10px;
    font-size: ${({ theme }) => theme.text.p1.fontSize};

    &__link {
      font-weight: 700;
    }

    &__divider {
      height: 1px;
      background-color: ${({ theme }) => theme.colors.white00};
      margin: 40px;
      padding: 0;
    }

    &__with_icon {
      display: flex;
      align-items: center;
      justify-content: center;
      .icon {
        width: 20px;
        min-width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 16px;
        img {
          object-fit: contain;
          display: block;
        }
      }
    }
  }
`

interface MenuLinkProps {
  to: string
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

const MenuLink: React.FC<MenuLinkProps> = ({ children, to, onClick }) => (
  <Link to={to}>
    <div
      onClick={onClick}
      role="link"
      tabIndex={0}
      className="menu-item menu-item__link"
      aria-hidden="true"
    >
      {children}
    </div>
  </Link>
)

interface MenuWithIconProps {
  href?: string
  icon: any
}

const MenuWithIcon: React.FC<MenuWithIconProps> = ({
  children,
  href,
  icon,
}) => (
  <a className="menu-item menu-item__with_icon" href={href}>
    <div className="icon">
      <img src={icon} alt="" />
    </div>
    {children}
  </a>
)

const Menu: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false)

  const onClick = () => setIsOpen((v) => !v)

  return (
    <>
      <MenuButton onClick={onClick}>
        <img src={isOpen ? menuIconActive : menuIcon} alt="menu" />
      </MenuButton>
      <MenuWrapper $isOpen={isOpen}>
        <MenuLink to="/#services" onClick={() => setIsOpen(false)}>
          Services
        </MenuLink>
        <MenuLink to="/blogs">Blog</MenuLink>
        <MenuLink to="/#contacts" onClick={() => setIsOpen(false)}>
          Contact us
        </MenuLink>
        <div className="menu-item menu-item__divider" />
        <MenuWithIcon icon={phoneIcon} href={PHONE_NUMBER.href}>
          {PHONE_NUMBER.label}
        </MenuWithIcon>
        <MenuWithIcon icon={mailIcon} href={EMAIL.href}>
          {EMAIL.label}
        </MenuWithIcon>
      </MenuWrapper>
    </>
  )
}

export default Menu
