import React from 'react'
import {
  ThemeProvider as StyledThemeProvider,
  createGlobalStyle,
} from 'styled-components'
import { normalize } from 'styled-normalize'

export const theme = {
  colors: {
    white00: '#FFFFFF',

    lightGrey50: '#F5F8FF',
    lightGrey100: '#DFE3ED',
    lightGrey200: '#C3CDDF',

    grey200: '#A9ADB5',
    grey300: '#535C6B',
    grey400: '#425C7F',

    blue50: '#F0F5FD',
    blue200: '#55A4F3',
    blue300: '#2B3DA9',
    blue400: '#1D1C99',

    purple400: '#5A3CCE',
    dark800: '#09183F',
  },
  gradientColors: {
    pastel400: ['#9AB5FD', '#AD9EE7'],
    peacock400: ['#356BFA', '#5A3CCE'],
  },
  fontFamily: {
    default: '"Nunito Sans", sans-serif',
  },
  text: {
    h1: {
      fontSize: '40px',
      fontWeight: 800,
      // lineHeight:
    },
    h2: {
      fontSize: '30px',
      fontWeight: 800,
    },
    h3: {
      fontSize: '20px',
      fontWeight: 800,
    },
    h4: {
      fontSize: '16px',
      fontWeight: 800,
    },
    h5: {
      fontSize: '14px',
      fontWeight: 800,
    },
    p1: {
      fontSize: '20px',
      fontWeight: 'normal',
    },
    p2: {
      fontSize: '16px',
      fontWeight: 'normal',
    },
    p3: {
      fontSize: '14px',
      fontWeight: 'normal',
    },
  },
  breakpoints: {
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
    xx: '1440px',
  },
}

const CreateGlobalStyle = createGlobalStyle`
  ${normalize}
  html, body {
    overflow-x: hidden;
  }
  * {
    font-family: "Nunito Sans", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
  }

  h1, h2, h3, h4, h5, h6, p {
    margin: 0;
  }

  a {
    text-decoration: none;
  }

  .jumptarget {
    ::before {
      content: '';
      display: block;
      height: 80px; /* fixed header height*/
      margin: -80px 0 0; /* negative fixed header height */
    }
  }
`

interface ThemeProviderProps {
  children: JSX.Element | JSX.Element[]
}

const ThemeProvider: React.FC = ({ children }: ThemeProviderProps) => (
  <StyledThemeProvider theme={theme}>
    <CreateGlobalStyle />
    {children}
  </StyledThemeProvider>
)

export default ThemeProvider
