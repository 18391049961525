import React, { useEffect, useState } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import classNames from 'classnames'

import logoImage from 'images/logo.svg'

import Container from './container'
import Menu from './menu'

const HeaderWrapper = styled.header`
  position: relative;

  .content {
    height: 50px;
    width: 100%;

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      height: 80px;
    }
  }

  a {
    color: ${({ theme }) => theme.colors.white00};
  }

  .content {
    top: 0;
    left: 0;
    right: 0;
    position: fixed;
    z-index: 1;
    background-color: transparent;
    transition: all 0.5s ease-in-out;

    &.isScrolled {
      background-color: ${({ theme }) => theme.colors.dark800};
    }

    & > div {
      height: 100%;
      display: flex;
      align-items: center;

      & > div {
        padding: 0 12px;
      }

      .left {
        flex-grow: 1;

        img {
          transition: all 0.5s ease-in-out;
          width: 126px;
          @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
            width: 221px;
          }
        }
      }

      .menu-tabs {
        display: none;
        & > div:not(:last-child) {
          padding-right: 40px;
        }

        @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
          display: flex;
        }
      }

      .menu-hamburger {
        position: relative;
        display: block;

        @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
          display: none;
        }
      }
    }
  }
`

const getScrollTop = () =>
  document.body.scrollTop || document.documentElement.scrollTop

const SCROLL_TRESHOLD = [150, 50]

const Header: React.FC = () => {
  const [isScrolled, setIsScrolled] = useState(false)

  useEffect(() => {
    const scrollListener = () => {
      const scrollTop = getScrollTop()

      if (!isScrolled && scrollTop >= SCROLL_TRESHOLD[0]) {
        setIsScrolled(true)
      } else if (isScrolled && scrollTop <= SCROLL_TRESHOLD[0]) {
        setIsScrolled(false)
      }
    }

    window.addEventListener('scroll', scrollListener)
    return () => window.removeEventListener('scroll', scrollListener)
  }, [isScrolled])

  return (
    <HeaderWrapper>
      <div className={classNames('content', { isScrolled })}>
        <Container>
          <div className="left">
            <Link to="/">
              <img src={logoImage} alt="KANOONth" />
            </Link>
          </div>
          <div className="menu-hamburger">
            <Menu />
          </div>
          <div className="menu-tabs">
            <div>
              <Link to="/#services">Services</Link>
            </div>
            {/* <div>
              <Link to="/blogs">Blog</Link>
            </div> */}
            <div>
              <Link to="/#contacts">Contact us</Link>
            </div>
          </div>
        </Container>
      </div>
    </HeaderWrapper>
  )
}

export default Header
