import React from 'react'
import styled from 'styled-components'

import logoImage from 'images/logo.svg'
import locationIcon from 'images/icons/location.svg'
import phoneIcon from 'images/icons/phone.svg'
import mailIcon from 'images/icons/mail.svg'
import facebookIcon from 'images/icons/facebook.svg'
import twitterIcon from 'images/icons/twitter.svg'
import lineIcon from 'images/icons/line.png'
import bubbleXS from 'images/bubbles/footer-xs.svg'
import bubbleMD from 'images/bubbles/footer-md.svg'

import { EMAIL, PHONE_NUMBER } from 'constants/contact'
import Container from './container'

const FooterWrapper = styled.footer`
  padding: 48px 0 80px 0;
  background-color: ${({ theme }) => theme.colors.dark800};
  position: relative;

  &,
  a {
    color: ${({ theme }) => theme.colors.white00};
    font-size: ${({ theme }) => theme.text.p2.fontSize};
    font-weight: ${({ theme }) => theme.text.p2.fontWeight};
    transition: color 0.15s;
  }

  a:hover {
    color: ${({ theme }) => theme.colors.lightGrey100};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    text-align: left;
    padding: 80px 0 56px 0;
  }

  .logo {
    padding: 0 12px;
    margin-bottom: 32px;
    text-align: center;

    img {
      width: 158px;
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
      text-align: left;
      margin-bottom: 40px;

      img {
        width: 221px;
      }
    }
  }

  & > .content {
    position: relative;
    .shadow {
      pointer-events: none;
      visibility: hidden;
    }
    .visible {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 100%;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .info {
    display: block;
    width: 100%;

    @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
      max-width: 900px;
      display: flex;
      flex-wrap: wrap;
    }

    .column {
      width: 100%;
      @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
        width: 50%;
      }
    }
  }

  #bubbleXS,
  #bubbleMD {
    position: absolute;
    display: block;
  }

  #bubbleXS {
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;

    @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
      display: none;
    }
  }

  #bubbleMD {
    display: none;
    height: 100%;
    right: 0;
    bottom: 0;
    top: 0;

    @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
      display: block;
    }
  }
`

const ItemWrapper = styled.div`
  padding: 0 12px;
  margin-bottom: 24px;
  display: flex;

  & > div {
    min-width: 0px;
  }

  .icon {
    width: 24px;
    min-width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      object-fit: contain;
      display: block;
    }
  }

  .text {
    padding-left: 20px;
    padding-top: 4px;
  }
`

interface FooterItemProps {
  name: string
  icon: any
  href?: string
}

const FooterItem: React.FC<FooterItemProps> = ({
  children,
  icon,
  name,
  href,
}) => {
  return (
    <ItemWrapper as={href ? 'a' : ''} href={href}>
      <div className="icon">
        <img src={icon} alt={name} />
      </div>
      <div className="text">{children}</div>
    </ItemWrapper>
  )
}

interface FooterContentProps {
  className?: string
}

const FooterContent: React.FC<FooterContentProps> = ({ className }) => {
  return (
    <Container className={className}>
      <div className="logo">
        <img src={logoImage} alt="KANOONth" />
      </div>
      <div className="info">
        <div className="column">
          <FooterItem name="location" icon={locationIcon}>
          100/98 Chaiyapruek 1 Village Soi Khum Klao 7 Khum Klao Road Lam PrathewLat Krabang, Bangkok, Thailand 10520
          </FooterItem>
          <FooterItem name="phone" href={PHONE_NUMBER.href} icon={phoneIcon}>
            {PHONE_NUMBER.label}
          </FooterItem>
          <FooterItem name="mail" href={EMAIL.href} icon={mailIcon}>
            {EMAIL.label}
          </FooterItem>
        </div>
        {/* <div className="column">
          <FooterItem name="facebook" icon={facebookIcon}>
            Kanoonth Studio
          </FooterItem>
          <FooterItem name="twitter" icon={twitterIcon}>
            @kanoonth
          </FooterItem>
          <FooterItem name="line" icon={lineIcon}>
            @kanoonth
          </FooterItem>
        </div> */}
      </div>
    </Container>
  )
}

const Footer: React.FC = () => {
  return (
    <FooterWrapper id="contacts" className="jumptarget">
      <img src={bubbleXS} alt="" id="bubbleXS" />
      <img src={bubbleMD} alt="" id="bubbleMD" />
      <div className="content">
        <FooterContent className="shadow" />
        <FooterContent className="visible" />
      </div>
    </FooterWrapper>
  )
}

export default Footer
